.rnc__notification-container--bottom-center,
.rnc__notification-container--bottom-full,
.rnc__notification-container--bottom-left,
.rnc__notification-container--bottom-right,
.rnc__notification-container--center,
.rnc__notification-container--top-center,
.rnc__notification-container--top-full,
.rnc__notification-container--top-left,
.rnc__notification-container--top-right {
  min-width: 325px;
  pointer-events: all;
  position: absolute;
}
.rnc__notification-container--bottom-center,
.rnc__notification-container--center,
.rnc__notification-container--top-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: calc(50% - 175px);
  max-width: 350px;
}
.rnc__notification-container--center {
  height: 100%;
  pointer-events: none;
  top: 20px;
}
.rnc__notification-container--bottom-full,
.rnc__notification-container--top-full {
  min-width: 100%;
  width: 100%;
}
.rnc__notification-container--bottom-full {
  bottom: 0;
}
.rnc__util--flex-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 325px;
  pointer-events: all;
}
.rnc__notification-container--top-center {
  top: 20px;
}
.rnc__notification-container--bottom-center {
  bottom: 20px;
}
.rnc__notification-container--top-left {
  left: 20px;
  top: 20px;
}
.rnc__notification-container--top-right {
  right: 20px;
  top: 20px;
}
.rnc__notification-container--bottom-left {
  bottom: 20px;
  left: 20px;
}
.rnc__notification-container--bottom-right {
  bottom: 20px;
  right: 20px;
}
.rnc__notification-container--mobile-bottom,
.rnc__notification-container--mobile-top {
  pointer-events: all;
  position: absolute;
}
.rnc__notification-container--mobile-top {
  left: 20px;
  right: 20px;
  top: 20px;
}
.rnc__notification-container--mobile-bottom {
  bottom: 20px;
  left: 20px;
  margin-bottom: -15px;
  right: 20px;
}
.rnc__notification-item--default {
  background-color: #007bff;
  border-left: 8px solid #0562c7;
}
.rnc__notification-item--default .rnc__notification-timer {
  background-color: #007bff;
}
.rnc__notification-item--default .rnc__notification-timer-filler {
  background-color: #fff;
}
.rnc__notification-item--default .rnc__notification-close-mark {
  background-color: #007bff;
}
.rnc__notification-item--success {
  background-color: #28a745;
  border-left: 8px solid #1f8838;
}
.rnc__notification-item--success .rnc__notification-timer {
  background-color: #28a745;
}
.rnc__notification-item--success .rnc__notification-timer-filler {
  background-color: #fff;
}
.rnc__notification-item--success .rnc__notification-close-mark {
  background-color: #28a745;
}
.rnc__notification-item--danger {
  background-color: #dc3545;
  border-left: 8px solid #bd1120;
}
.rnc__notification-item--danger .rnc__notification-timer {
  background-color: #dc3545;
}
.rnc__notification-item--danger .rnc__notification-timer-filler {
  background-color: #fff;
}
.rnc__notification-item--danger .rnc__notification-close-mark {
  background-color: #dc3545;
}
.rnc__notification-item--info {
  background-color: #17a2b8;
  border-left: 8px solid #138b9e;
}
.rnc__notification-item--info .rnc__notification-timer {
  background-color: #17a2b8;
}
.rnc__notification-item--info .rnc__notification-timer-filler {
  background-color: #fff;
}
.rnc__notification-item--info .rnc__notification-close-mark {
  background-color: #17a2b8;
}
.rnc__notification-item--warning {
  background-color: #eab000;
  border-left: 8px solid #ce9c09;
}
.rnc__notification-item--warning .rnc__notification-timer {
  background-color: #eab000;
}
.rnc__notification-item--warning .rnc__notification-timer-filler {
  background-color: #fff;
}
.rnc__notification-item--warning .rnc__notification-close-mark {
  background-color: #eab000;
}
.rnc__notification-item--awesome {
  background-color: #685dc3;
  border-left: 8px solid #4c3fb1;
}
.rnc__notification-item--awesome .rnc__notification-timer {
  background-color: #685dc3;
}
.rnc__notification-item--awesome .rnc__notification-timer-filler {
  background-color: #fff;
}
.rnc__notification-item--awesome .rnc__notification-close-mark {
  background-color: #685dc3;
}
@keyframes timer {
  0% {
    width: 100%;
  }
  to {
    width: 0;
  }
}
.rnc__base {
  height: 100%;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 9000;
}
.rnc__notification-item {
  border-radius: 3px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  margin-bottom: 15px;
  position: relative;
}
.rnc__notification-container--bottom-full .rnc__notification-item,
.rnc__notification-container--top-full .rnc__notification-item {
  border-radius: 0;
  margin-bottom: 0;
}
.rnc__notification-container--bottom-full .rnc__notification,
.rnc__notification-container--top-full .rnc__notification {
  width: 100% !important;
}
.rnc__notification-timer {
  margin-top: 10px;
  width: 100%;
}
.rnc__notification-timer,
.rnc__notification-timer-filler {
  border-radius: 5px;
  height: 3px;
}
.rnc__notification-title {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 5px;
}
.rnc__notification-message {
  word-wrap: break-word;
  color: #fff;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 0;
  margin-top: 0;
  max-width: calc(100% - 15px);
}
.rnc__notification-content {
  display: inline-block;
  padding: 10px 15px;
  width: 100%;
}
.rnc__notification-close-mark {
  border-radius: 50%;
  display: inline-block;
  height: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 18px;
}
.rnc__notification-close-mark:after {
  color: #fff;
  content: '\D7';
  font-size: 12px;
  left: 50%;
  position: absolute;
  top: 50%;
  opacity: translate(0);
}
.rnc__notification-container--mobile-bottom .notification,
.rnc__notification-container--mobile-bottom .rnc__notification-item,
.rnc__notification-container--mobile-top .notification,
.rnc__notification-container--mobile-top .rnc__notification-item {
  max-width: 100%;
  width: 100%;
}
.rnc__notification-container--bottom-right .notification,
.rnc__notification-container--top-right .notification {
  margin-left: auto;
}
.rnc__notification-container--bottom-left .notification,
.rnc__notification-container--top-left .notification {
  margin-right: auto;
}
.rnc__notification-container--mobile-bottom .notification,
.rnc__notification-container--mobile-top .notification {
  margin-left: auto;
  margin-right: auto;
}
