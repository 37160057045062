:root {
  --custom-blue-color: #015aa3;
  --custom-grey-color: #f2f7fe;
}

.CalendarDay__default {
  border: 0px solid white;
}

.CalendarDay__selected_start {
  border-top-left-radius: 30%;
  border-bottom-left-radius: 30%;
  background: var(--custom-blue-color);
}

.CalendarDay__selected_end {
  border-top-right-radius: 30%;
  border-bottom-right-radius: 30%;
  background: var(--custom-blue-color);
}

.CalendarDay__selected_span {
  background:  var(--custom-grey-color);
  color: black;
}

.CalendarDay__default:hover {
  border: 0px solid white;
  background: var(--custom-blue-color);
  color: white;
  border-radius: 10%;
}
.CalendarDay__hovered_span {
  background:  var(--custom-grey-color);
}

.DayPickerNavigation_button {
  border: 0px;
}

.DayPicker,
.DateRangePicker_picker {
  border-radius: 20px;
  overflow: hidden;
  height: 310px !important;
}

.DateRangePicker_picker {
  left: calc(100% / -2) !important;
  top: 0 !important;
}

.CalendarMonthGrid {
  margin-top: 0px;
  width: 100% !important;
}

.DayPicker_transitionContainer,
.DayPicker_focusRegion {
  height: 300px !important;
  padding-bottom: 10px;
}

.CalendarMonth_caption {
  font-size: 16px;
}

.DateRangePickerInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  overflow: hidden;
  background: white;
  margin-top: 10px;
  border: 0px;
  border-radius: 5px;
}

/* Hidden elements */
.DayPickerKeyboardShortcuts_buttonReset,
.DateInput,
.DateRangePickerInput_arrow,
.DateInput_fangStroke,
.DateInput_fangShape {
  opacity: 0;
  z-index: -999;
}

.DateRangePicker {
  height: 310px;
}


